.progress-line-block{
    .title{
        font-size: 1.5em;
        font-weight: bold;
    }
    .line_wrapper{
        padding: 22px 0px;
        position: relative;
        overflow: hidden;
    }
    .main{
        width: 100%;
        padding: 2px;
        border-radius: 30px;
        background-color: #888888;
        position: relative;
        overflow: hidden;
    }
    .progress{
        height: 30px;
        border-radius: 30px;
        position: relative;
    }
    .value{
        width: 78px;
        height: 78px;
        position: absolute;
        top: 39px;
        transform: translate(-100%, -50%);
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 78px;
        border: 1px solid #888888;
        font-size: 30px;
        line-height: 78px;
        text-align: center;
        font-weight: bold;
    }
}