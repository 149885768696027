@use "@general/styles/bootstrap/boostrap_configuration" as bst;
@use "sass:map";

.home-hero {
    width: 100%;
    min-height: calc(100vh - 70px);
    position: relative;
    background-color: bst.$gray-900;
    margin: 0;
    padding: 0;
    overflow: hidden;

    .hero-text-wrapper {
        min-height: calc(100vh - 70px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .phone-emulated-wrapper {
        display: flex;
        justify-content: flex-end;
        height: 100%;
    }

    .hero-text-block {
        .hero-title {
            font-size: 60px;
            font-weight: bold;
        }

        .hero-sub-title {
            font-size: 30px;
        }

        .btn {
            font-size: 30px;
            padding: 5px 40px;
            font-weight: bold;
            margin-top: 60px;
            width: 70%;
            background-color: bst.$gray-900;
            border: 1px solid bst.$white;

            &:hover {
                background-color: bst.$gray-800;
            }
        }
    }

    .wave {
        background-image: url("../../../assets/home/wave.svg");
        background-repeat: no-repeat;
        height: 100%;
        width: 50%;
        background-size: 100% auto;
        background-position: right bottom;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.2;
    }

    .phone-emulated {
        width: 450px;
        height: 880px;
        margin: auto 0;
        background-image: url("../../../assets/home/phone_frame.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        position: relative;
        text-align: center;
        font-size: 22px;
        font-weight: bold;

        .web-development {
            position: absolute;
            left: 90px;
            top: 70px;
            width: 300px;
            height: 215px;
            background-image: url("../../../assets/home/figure_web_dev.svg");
            background-repeat: no-repeat;
            background-size: contain;
            padding-top: 135px;
        }

        .erp-development {
            position: absolute;
            left: 30px;
            bottom: 270px;
            width: 218px;
            height: 215px;
            background-image: url("../../../assets/home/figure_erp_dev.svg");
            background-repeat: no-repeat;
            background-size: contain;
            padding-top: 135px;
        }

        .devops-development {
            position: absolute;
            right: 30px;
            bottom: 370px;
            width: 218px;
            height: 215px;
            background-image: url("../../../assets/home/figure_devops_dev.svg");
            background-repeat: no-repeat;
            background-size: contain;
            padding-top: 130px;
        }

        .ads-development {
            position: absolute;
            left: 90px;
            bottom: 60px;
            width: 280px;
            height: 215px;
            background-image: url("../../../assets/home/figure_ads_dev.svg");
            background-repeat: no-repeat;
            background-size: contain;
            padding-top: 110px;
        }

        .since-text {
            position: absolute;
            right: 60px;
            bottom: 40px;
        }
    }

    @include bst.media-breakpoint-down(xl) {
        .wave {
            width: 100%;
            transform: scale(-1);
        }
        .phone-emulated-wrapper {
            display: flex;
            justify-content: center;
            padding-bottom: map.get(bst.$spacers, 5);
        }
    }

    @include bst.media-breakpoint-down(sm) {
        .hero-text-block {
    
            .btn {
                width: 100%;
            }
        }
        .phone-emulated {
            width: 100%;
            height: 880px;
            background-image: none;
    
            .web-development {
                left: 10px;
                top: 0px;
            }
    
            .erp-development {
                left: -5px;
                bottom: 270px;
            }
    
            .devops-development {
                right: -5px;
                bottom: 460px;
            }
    
            .ads-development {
                right: 10px;
                left: auto;
                bottom: 60px;
            }
    
            .since-text {
                right: 10px;
                bottom: 10px;
            }
        }
    }
}